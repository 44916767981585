import React from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ogIMage from "../../../../images/og.png";

const OurStoryImpactFour = () => {
    return (
        <Layout>
            <Seo
                title={" Brighten lives of elderly women of Vrindavan"}
                description="Experience transformative collaboration between Sedin & PY Trust as we embark on a mission to brighten the lives of elderly women, providing compassion & support. "
                image={ogIMage}
            />
            <section className="csr-our-stories">
                <div className="container container--first-v3">
                    <div className="csr-our-stories__text gap-2x">
                        <div className="text-p3 gap-2x csr-our-stories__text-breadcrumbs text-fw-normal">
                            <Link to={`/about/csr`} className="text-decor-none">
                                CSR
                            </Link>
                            /<Link className="text-decore none">Our Stories Of Impact</Link>
                        </div>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-2x">
                            Healing Hearts, Changing Lives: Sedin & PY Trust Brighten Lives of
                            Elderly Women
                        </h1>
                        <div className="csr-our-stories__banner-img gap-3x">
                            <StaticImage
                                src="../../../../images/csrpage/our-stories-banner-img-4.png"
                                alt="banner-img"
                                loading="lazy"
                                placeholder="blurred"
                                className="banner-img"
                            />
                        </div>
                        <h2 className="text-h2 text-clr-primary text-fw-medium gap-1x">
                            Our Compassion in Action
                        </h2>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            In a heartwarming collaboration, Sedin and the esteemed Paramhansa
                            Yogananda Trust have joined hands to create a profound impact in the
                            lives of elderly destitute women, predominantly widows, residing in and
                            around the serene city of Vrindavan.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Through the Maatri Seva Program, we are on a mission to bring hope,
                            dignity, and joy to these remarkable women who have faced adversity with
                            grace and resilience. Together, we are committed to providing essential
                            resources, catering to their needs and well-being. 
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Beyond the material support, our joint efforts seek to uplift their
                            spirits and provide them with the comfort and support they need during
                            their golden years.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Together, we can make a positive impact in the lives of elderly women,
                            spreading smiles and brightening their days in Vrindavan.
                        </p>
                    </div>
                </div>
            </section>
            {/* our-stories-image-gallery section */}
            <section className="csr-our-stories-img-gallery">
                <div className="container container--last">
                    <div className="csr-our-stories-img-gallery__grid">
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-04-1.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-04-2.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-04-3.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-04-4.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-04-5.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OurStoryImpactFour;
